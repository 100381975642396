function getPositionEventString(el) {
  let area = "MB";
  let element = "null";
  let parentArea = el.closest('[data-area]');
  let parentElement = el.closest('[data-element]');

  if (parentArea) {
    area = parentArea.dataset.area;
  }

  if (parentElement) {
    element = parentElement.dataset.element;
  }

  return [area, element].join('/');
}

export default function(element) {
  let action = decodeURIComponent(document.body.dataset.trackingData);
  let position = getPositionEventString(element);

  let closest = element.closest('[data-index]');
  let index = closest ? closest.dataset.index : 'null';

  let couponId = element.dataset.couponId || 'null';
  let bannerId = element.dataset.id; //works only on banners;

  return [action, position, index, couponId, bannerId].join('/');
}
