require('./polyfill');
import initializer from "./initializer";
import mediator from "libs/mediator";
import Utils from "./Utils";
import {
  CampaignCouponsFilter,
  Categories,
  CouponFilters,
  ExtendedCouponFilter,
  Coupons,
  JumpToLinks,
  ShopsIndex,
  Vote,
  HeroCountdown,
  CouponVote,
} from "./modules";
import {
  Banners,
  BannersUtil,
  Flyout,
  Modal,
  ModalClickout,
  ModalGift,
  NewsLetters,
  LazyImagesUpdate,
  Search,
  ShowMore,
  LazyBackgrounds,
  ScrollToCoupon,
  ScrollToTop,
  ToggleWelcomeText,
} from "./modules/common";
import {
  CouponsGrid,
  FeaturedCoupons,
  QuickLinks,
  ShopBubbles,
  Carousel,
  PremiumSlider,
  OfferCarousel,
  EarthmarkShops,
  PremiumCampaignSlider,
  CuratedCoupons
} from "./widgets";

class Facade {
  constructor() {
    this.Utils = Utils;
    this.Common();
  }

  start() {
    initializer.init();
  }

  on(channel, fn) {
    mediator.on(channel, fn);
  }

  Common() {
    LazyBackgrounds.run();
    Banners.run();
    BannersUtil.run();
    Flyout.run();
    Modal.run();
    ModalClickout.run();
    ModalGift.run();
    NewsLetters.run();
    Search.run();
    ShowMore.run();
    LazyImagesUpdate.run();
    CouponVote.run();
    ToggleWelcomeText.run();
    ScrollToCoupon.run();
    ScrollToTop.run();
    CuratedCoupons.run();
  }

  trigger(channel) {
    mediator.trigger(channel);
  }

  Vote() {
    Vote.run();
  }

  Categories() {
    Categories.run();
  }

  ShopsIndex() {
    ShopsIndex.run();
  }

  CampaignCouponsFilter() {
    CampaignCouponsFilter.run();
  }

  CouponFilters() {
    CouponFilters.run();
  }

  ExtendedCouponFilter() {
    ExtendedCouponFilter.run();
  }

  Coupons() {
    Coupons.run();
  }

  JumpToLinks() {
    JumpToLinks.run();
  }

  HeroCountdown() {
    HeroCountdown.run();
  }

  Widgets() {
    CouponsGrid.run();
    FeaturedCoupons.run();
    QuickLinks.run();
    ShopBubbles.run();
    Carousel.run();
    PremiumSlider.run();
    OfferCarousel.run();
    EarthmarkShops.run();
    PremiumCampaignSlider.run();
  }

  log() {
    this.Utils.log.apply(console, arguments);
  }
}

export default Facade;
