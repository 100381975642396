import gaEvent from "./event.interface";

function pianoLoaded() {
  return typeof window.pa !== 'undefined';
}

function sendPianoEvent(eventObj: gaEvent) {
  if(!pianoLoaded()) {
    return false;
  }

  const eventName = eventObj['event'] || "click";

  const eventProps = {
    'click': eventName,
    'click_chapter1': eventObj['eventCategory'],
    'click_chapter2': eventObj['eventAction'],
    'click_chapter3': eventObj['eventLabel']
  }

  window._paq.push(['sendEvent', 'click.action', eventProps]);
}

export {pianoLoaded, sendPianoEvent}
