/* global axios, rootDir */

import log from "../Utils/log";
import mediator from "libs/mediator";

const inputTokenSelector = 'input[name=authenticity_token]';

export default (function() {
  function setCSRFToken(token) {
    window.csrf_token = token;
    document.querySelectorAll(inputTokenSelector).forEach((item) => {
      item.value = token;
    })
  }

  function handleFormFocus(event) {
    if (!event.target.form) {
      return;
    }

    const form = event.target.form;
    if (form.dataset.isUpdatedCsrf) {
      return;
    }

    if (!form.querySelector(inputTokenSelector)) {
      return;
    }

    axios
      .get(rootDir + '/tracking/csrf')
      .then(function(response) {
        setCSRFToken(response.data.csrf_token);
        form.dataset.isUpdatedCsrf = true;
      })
      .catch(err => {
        console.error(err.response);
      });
  }

  function run() {
    init();
    mediator.on("modal:show", init);
  }

  function init() {
    log("CSRFManager init");
    let forms = document.querySelectorAll("form");

    forms.forEach((form) => {
      form.addEventListener('focus', handleFormFocus, true);
    });
  }

  return {
    run: run
  };
})();
