/* global axios, rootDir */
import Utils from "../Utils";

export default (function() {

  function isRedirectPage() {
    return document.body.classList.contains('coupons') && document.body.classList.contains('clickout');
  }

  function triggerTrackingUserEvent() {
    let event = new CustomEvent('tracking_user_set');
    document.dispatchEvent(event);
  }

  function setUserTracking() {
    if (isRedirectPage()) {
      return;
    }

    let urlWithoutQueryString = window.location.href.split('?')[0];
    let allVars = {};

    if (window.location.href.indexOf('?') !== -1 && urlWithoutQueryString.indexOf('search') === -1) {
      allVars = Utils.getUrlVars();
    }

    let referrer = document.referrer;

    if (referrer === '' || referrer === "undefined") {
      referrer = 'unknown';
    }

    allVars['referrer'] = referrer;

    axios
      .post(rootDir + '/tracking/set', {query_string_params: allVars})
      .then(function(response) {
        let data = response.data;
        window.pc_tracking_user_id = data.tracking_user_id;
        triggerTrackingUserEvent();
      })
      .catch(err => {
        console.error(err.response);
      });
  }

  return {
    run: setUserTracking
  };
})();
