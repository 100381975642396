import log from "../Utils/log";
import isMobile from "../Utils/isMobile";
import debounce from 'lodash/debounce';

function run() {
  const widgets = document.querySelectorAll('[data-widget="offerCarousel"]');

  if (!widgets) return;

  widgets.forEach((widget) => {
    log('OfferCarousel:init');

    const container = widget.querySelector('[data-container]') as HTMLElement;
    const arrowContainerPrev = widget.querySelector('[data-container-arrow-prev]') as HTMLElement;
    const arrowContainerNext = widget.querySelector('[data-container-arrow-next]') as HTMLElement;
    const arrowNavigationPrev = widget.querySelector('[data-navigation-arrow-prev]') as HTMLElement;
    const arrowNavigationNext = widget.querySelector('[data-navigation-arrow-next]') as HTMLElement;
    const scrollIndicator = widget.querySelector('[data-navigation-indicator]') as HTMLElement;
    const scrollTracker = widget.querySelector('[data-navigation-tracker]') as HTMLElement;
    const offers = widget.querySelectorAll<HTMLElement>('[data-offer-item]');
    const arrowNavModifier = 'offer-carousel__navigation-arrow--active';

    let scrollStepMobile: number;
    let scrollStepDesktop: number;
    let gap: number;

    const resizeObserver = new ResizeObserver(debounce(() => {
      const offerWidth: number = offers[0].offsetWidth;

      if (offers[2] && offers[1]) {
        gap = offers[2].getBoundingClientRect().left - offers[1].getBoundingClientRect().left - offerWidth;
      } else {
        gap = 60;
      }

      scrollStepMobile = offerWidth;
      scrollStepDesktop = (offerWidth * 2) + gap;

      if (offers.length <= 1 || (!isMobile.mobile() && offers.length <= 4)) {
        inactiveAllArrows();
      }

      showWidthTracker();
      scrollContainer();
    }, 400));

    if (offers && offers.length > 0) {
      resizeObserver.observe(container);
    }

    arrowContainerPrev.addEventListener('click', scrollToPrevSlide);
    arrowNavigationPrev.addEventListener('click', scrollToPrevSlide);
    arrowContainerNext.addEventListener('click', scrollToNextSlide);
    arrowNavigationNext.addEventListener('click', scrollToNextSlide);
    container.addEventListener('scroll', () => window.requestAnimationFrame(scrollContainer));

    function scrollToPrevSlide() {
      let valueScroll: number;

      if (isMobile.mobile()) {
        valueScroll = container.scrollLeft - scrollStepMobile - (gap / 2);
      } else {
        valueScroll = container.scrollLeft - scrollStepDesktop - gap;
      }
      container.scrollLeft = valueScroll;
    }

    function scrollToNextSlide() {
      let valueScroll: number;

      if (isMobile.mobile()) {
        valueScroll = container.scrollLeft + scrollStepMobile + (gap / 2);
      } else {
        valueScroll = container.scrollLeft + scrollStepDesktop + gap;
      }
      container.scrollLeft = valueScroll;
    }

    function showPrevArrows() {
      if (arrowContainerPrev.classList.contains('hidden') || !arrowNavigationPrev.classList.contains(arrowNavModifier)) {
        arrowContainerPrev.classList.remove('hidden');
        arrowNavigationPrev.classList.add('offer-carousel__navigation-arrow--active');
      }
    }

    function showNextArrows() {
      if (arrowContainerNext.classList.contains('hidden') || !arrowNavigationNext.classList.contains(arrowNavModifier)) {
        arrowContainerNext.classList.remove('hidden');
        arrowNavigationNext.classList.add(arrowNavModifier);
      }
    }

    function hideNextArrows() {
      arrowContainerNext.classList.add('hidden');
      arrowNavigationNext.classList.remove(arrowNavModifier);
    }

    function hidePrevArrows() {
      arrowContainerPrev.classList.add('hidden');
      arrowNavigationPrev.classList.remove(arrowNavModifier);
    }

    function showWidthTracker() {
      const indicatorWidth = scrollIndicator.offsetWidth;
      const offerLength = offers.length;
      let numberOfScrolls: number;

      if (isMobile.mobile()) {
        numberOfScrolls = offerLength;
      } else if (offerLength > 4) {
        numberOfScrolls = Math.ceil((offerLength / 2) - 1);
      } else {
        numberOfScrolls = 1;
      }

      const widthOfTracker = indicatorWidth / numberOfScrolls;
      scrollTracker.style.width = widthOfTracker + "px";
    }

    function inactiveAllArrows() {
      hidePrevArrows();
      hideNextArrows();
    }

    function scrollContainer() {
      const scrolled = container.scrollLeft * 100 / (container.scrollWidth - container.offsetWidth);
      scrollTracker.style.left = (scrollIndicator.offsetWidth - scrollTracker.offsetWidth) * scrolled / 100  + "px";

      if (scrolled == 0) {
        hidePrevArrows();
      } else if (scrolled == 100) {
        hideNextArrows();
      } else {
        showNextArrows();
        showPrevArrows();
      }
    }
  });
}

export default {run};
