import {gaLoaded, sendGAEvent} from "./gaTracking";
import {sendPianoEvent} from "./pianoTracking";
import getActionString from "./getActionString";
import Utils from "./../Utils";

export default (function() {
  let ChangedBehaviour, NoCookieDrop, sameTab, newTab;
  let clickoutSelector = "data-coupon-url";

  function defineClickoutBehaviour(element) {
    let couponUrl = element.dataset.couponUrl;
    let href = element.getAttribute('href');
    ChangedBehaviour = element.dataset.changedBehaviour;
    NoCookieDrop = element.dataset.noCookie;

    if (NoCookieDrop) {
      newTab = null;
      sameTab = href;
    } else if (ChangedBehaviour) {
      newTab = couponUrl;
      sameTab = href;
    } else {
      newTab = href;
      sameTab = couponUrl;
    }
  }

  function trackNonClickoutLinks(element) {
    if (element.tagName === "A") {
      let isTrackingElement = element.closest('[data-tracking-element]');
      let Event = getClickoutEvents(element);

      if (isTrackingElement) {
        Event = {
          'event': 'clickout',
          'eventCategory': 'Clickout - Fallback URL',
          'eventAction': getActionString(element),
          'eventLabel': element.getAttribute('data-tracking-label'),
          'eventValue': '25'
        };
      }
      sendGAEvent(Event);
      sendPianoEvent(Event);
    }
  }

  function trackEventLayerAndRedirect(element) {
    if (newTab) {
      window.open(newTab);
    }

    const Event = {
      ...getClickoutEvents(element),
      eventCallback: function() {
        window.location.href = sameTab;
        if (ChangedBehaviour || NoCookieDrop) {
          window.location.reload(true);
        }
      }
    };
    sendPianoEvent(Event);

    if (gaLoaded()) {
      sendGAEvent(Event);
    } else {
      window.location.href = sameTab;
      if (ChangedBehaviour || NoCookieDrop) {
        window.location.reload(true);
      }
    }
  }

  function getClickoutEvents(element) {
    let isBanner = element.closest('[data-banner]');
    let eventCategory = element.dataset.shopName || document.body.dataset.trackingData;

    let Event = {
      'event': 'clickout',
      'eventCategory': 'Clickout - ' + Utils.unescape(eventCategory.toLowerCase()),
      'eventAction': getActionString(element),
      'eventLabel': element.dataset.couponTitle,
      'eventValue': '25'
    };

    if (isBanner) {
      Event = {
        'event': 'clickout',
        'eventCategory': 'Clickout - Banner',
        'eventAction': getActionString(element),
        'eventLabel': element.getAttribute('data-banner-event-label'),
        'eventValue': '25'
      };
    }
    return Event;
  }

  function shouldBeSkipped(element) {
    let skip = false;
    let hostName = document.location.hostname;
    let referrer = document.referrer ? new URL(document.referrer).hostname : 'null';
    let parentArea = element.closest('[data-area]') ? element.closest('[data-area]').dataset.area : 'MB';

    if (hostName === referrer && parentArea === "MOD") {
      skip = true;
    }

    return skip;
  }

  function init() {
    document.addEventListener("click", function(ev) {
      let parentClickout = ev.target.closest(`[${clickoutSelector}]`);
      let parentElement = ev.target.closest("[data-element]");
      let noParent = ev.target.dataset.element;

      switch (true) {
        case shouldBeSkipped(ev.target):
          break;

        case Boolean(parentClickout):
          ev.preventDefault();
          defineClickoutBehaviour(parentClickout);
          trackEventLayerAndRedirect(parentClickout);
          break;

        case Boolean(parentElement):
          trackNonClickoutLinks(parentElement);
          break;

        case Boolean(noParent):
          trackNonClickoutLinks(ev.target);
          break;

        default:
          break;
      }

    });
  }

  return {
    run: init
  };
})();
