import log from "./Utils/log";
import mediator from "libs/mediator";
import Tracking from "./Tracking";
import TrackingLegacy from "./Tracking.legacy";
import TrackingEmbed from "./Tracking.embed";

export default (function() {
  function getCurrentPage() {
    return document.body.getAttribute("data-init");
  }

  return {
    init: function() {
      log("Application Init");
      if (window.embed) {
        TrackingEmbed.run();
      } else if (window.newTracking) {
        Tracking.run();
      } else {
        TrackingLegacy.run();
      }

      let pageName = getCurrentPage();
      mediator.trigger(pageName + ":init");
    }
  };
})();
