import mediator from "libs/mediator";
import {sendGAEvent} from "./gaTracking";
import {sendPianoEvent} from "./pianoTracking";

export default (function() {
  function init() {
    mediator.on("newsletter-modal:track", function() {
      let newsletterModal = document.querySelector(".newsletter-modal");

      const Event = {
        'event': newsletterModal.dataset.event,
        'eventCategory': "NewsLetter",
        'eventAction': newsletterModal.dataset.eventAction,
        'eventLabel': newsletterModal.dataset.eventLabel
      }

      sendGAEvent(Event);
      sendPianoEvent(Event);
    });
  }

  return {
    run: init
  };
})();
