import mediator from "libs/mediator";

import {sendGAEvent} from "./gaTracking";
import {sendPianoEvent} from "./pianoTracking";

export default (function() {
  function copyButton() {
    mediator.on("modal:show", () => {
      let copyBtn = document.querySelector(".modal-clickout__copy");

      if (copyBtn) {
        let eventLabel = document.querySelector("#my-modal .coupon__title").innerText.trim();
        const Event = {
          'event': 'clickout',
          'eventCategory': 'Misc',
          'eventAction': "copy_btn",
          'eventLabel': eventLabel,
          'eventValue': '25',
        };

        copyBtn.addEventListener("click", () => {
          sendGAEvent(Event);
          sendPianoEvent(Event);
        });
      }
    });
  }

  return {
    run: copyButton
  };
})();
