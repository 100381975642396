import UserTracking from "./UserTracking";
import NewsletterFormTracking from "./NewsletterFormTracking";
import ClickoutTracking from "./ClickoutTracking";
import ElementsTracking from "./ElementsTracking";
import CSRFManager from "./CSRFManager";

export default (function() {
  function run() {
    UserTracking.run();
    NewsletterFormTracking.run();
    ClickoutTracking.run();
    ElementsTracking.run();
    CSRFManager.run();
  }

  return {
    run: run
  };
})();
