import gaEvent from "./event.interface";

function gaLoaded() {
  return typeof window.ga !== 'undefined';
}

function sendGAEvent(eventObj: gaEvent) {
  if (gaLoaded()) {
    window.eventLayer.push(eventObj);
  }
}

export {gaLoaded, sendGAEvent}
